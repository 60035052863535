import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Authors from "../../components/Authors";
import {
  StyledPostTitle,
  StyledPostH1,
  StyledPostH2,
  StyledPostH3,
  BlogPostContainer,
  BlogPostContent,
} from "./styles";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import NotFound from "../NotFound";

const BlogPost = ({ posts, people }) => {
  let { postId } = useParams();

  let post = posts.find((p) => p.slug === postId);

  const [postContent, setPostContent] = useState("");

  useEffect(() => {
    if (post !== undefined) {
      fetch(`${process.env.PUBLIC_URL}/BlogPosts/${post.slug}.md`)
        // note that if postContentUrl does not exist, the response will still
        // be okay, because our 404 pages do not set the 404 status code
        .then((response) => (response.ok ? response.text() : Promise.reject()))
        // this means that if the markdown file doesn't exist, content will
        // be filled with the HTML of the 404 page.
        .then((content) => setPostContent(content))
        .catch(() => setPostContent("Error loading blog post content."));
    }
  }, [post]);

  if (post !== undefined) {
    return (
      <BlogPostContainer>
        <BlogPostContent>
          <StyledPostTitle>{post.title}</StyledPostTitle>
          <h5>
            <Authors names={post.authors} people={people} />
          </h5>
          <h5>{post.date}</h5>
          <ReactMarkdown
            children={postContent}
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
            components={{
              h1: StyledPostH1,
              h2: StyledPostH2,
              h3: StyledPostH3,
            }}
          />
        </BlogPostContent>
      </BlogPostContainer>
    );
  } else {
    return <NotFound />;
  }
};

export default BlogPost;
