import React from "react";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
// import ScrollToTop from "./components/Scroll";
import ScrollMemory from "react-router-scroll-memory";

// Necessary for react-snap
import { hydrate, render } from "react-dom";

// Avoid issue with react-snap: "console.log at /khouryvis.github.io/: TypeError: a[t].name.trim(...).replaceAll is not a function"
import replaceAllInserter from "string.prototype.replaceall";
replaceAllInserter.shim();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Code for react-snap to create a static snapshot using hydrate
let appFunction;

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  appFunction = hydrate;
} else {
  appFunction = render;
}

appFunction(
  <React.StrictMode>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      {/* <ScrollToTop /> */}
      <ScrollMemory />
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  rootElement,
);
