import React from "react";
// import Media from "../Media";
const Footer = ({ user }) => {
  return (
    <>
      <hr />
      {/* <Media media ={basics} /> */}
      <div className="col-6 col-md">
        <p style={{ color: "gray" }}>
          Khoury Vis Lab — Northeastern University
          <br />
          * West Village H, Room 302, 440 Huntington Ave, Boston, MA 02115, USA
          <br />
          * 100 Fore Street, Portland, ME 04101, USA
          <br />* Carnegie Hall, 201, 5000 MacArthur Blvd, Oakland, CA 94613,
          USA
        </p>
      </div>
    </>
  );
};

export default Footer;
