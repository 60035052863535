import React from "react";

const PersonLink = ({ name, people }) => {
  if (name in people) {
    let pageLink =
      process.env.PUBLIC_URL +
      "/people/" +
      people[name].name.trim().replaceAll(" ", "-") +
      "/";
    return <a href={pageLink}>{name}</a>;
  } else {
    return name;
  }
};

export default PersonLink;
