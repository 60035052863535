import styled from "styled-components";
import { Row, Image } from "react-bootstrap";

export const StyledHeading = styled.div`
  font-size: 15px;
  font-weight: 600;
  padding-top: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

export const BibTexStyled = styled.pre`
  display: block;
  font-family:
    Courier New,
    monospace;
  font-size: 16px;
  line-height: 22px;
  white-space: pre-wrap;
  margin: 1em 0px;
`;

export const StyledImage = styled(Image)`
  margin-top: 10px;
  transition: transform 0.2s;
  width: 100%;
  object-fit: cover;
  &:hover {
    cursor: pointer;
    -moz-box-shadow: 0 0 10px #377eb8;
    -webkit-box-shadow: 0 0 10px #377eb8;
    box-shadow: 0 0 10px #377eb8;
  }
`;

export const ProTitle = styled.a`
  font-weight: 600;
  font-size: 24px;
  color: #000;
  text-decoration: none;
  &:hover {
    color: #377eb8;
    text-decoration: none;
  }
`;

export const PubRow = styled(Row)`
  padding: 10px 0px;
`;
