import React from "react";

import { useState } from "react";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/styles.css";

const Culture = ({ culture }) => {
  const [index, setIndex] = useState(-1);

  const photos = culture.photos.slice().reverse();

  const sizes = {
    size: "960px",
    // These values are set based on the bootstrap Container defaults.
    // size corresponds to the width we have for the photo gallery (the below div)
    // at different viewport width.
    sizes: [
      { viewport: "(max-width: 576px)", size: "540px" },
      { viewport: "(max-width: 768px)", size: "720px" },
      { viewport: "(max-width: 992px)", size: "960px" },
      { viewport: "(max-width: 1200px)", size: "1140px" },
      { viewport: "(max-width: 1400px)", size: "1320px" },
    ],
  };

  return (
    <div>
      <PhotoAlbum
        photos={photos}
        sizes={sizes}
        layout="rows"
        onClick={({ index }) => setIndex(index)}
      />

      <Lightbox
        slides={photos}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        // enable optional lightbox plugins
        plugins={[Captions]}
      />
    </div>
  );
};

export default Culture;
