import React from "react";
import {
  RecuitmentContainer,
  RecruitmentContent,
  PositionTitle,
  PositionSubTitle,
} from "./styles";
import { StyledTitle } from "../../styles";

import "./style.css";

const Recruitment = () => {
  return (
    <RecuitmentContainer>
      <RecruitmentContent>
        <header className="article-header">
          <StyledTitle>Open Positions</StyledTitle>
        </header>
        <div className="article-content">
          <p>
            We have a growing focus on data visualization at Northeastern. The{" "}
            <a href="http://www.khoury.northeastern.edu/">
              Khoury College of Computer Sciences
            </a>{" "}
            has several professors focused on{" "}
            <a href="https://vis.khoury.northeastern.edu">
              visualization research
            </a>
            . They also support two new degree programs in{" "}
            <a href="http://www.khoury.northeastern.edu/research-area/data-science/">
              Data Science
            </a>
            . The college includes a strong team of 62 tenured and tenure-track
            faculty and another 55 teaching faculty, and the College plans to
            continue hiring. Northeastern is also now top ranked among top
            computer science schools in terms of publications at selective
            venues.
          </p>
          <p>
            Beyond Khoury College, visualization work is being conducted across
            campus by faculty in Art and Design, Journalism, Politics,
            Engineering, and the Library. The interdisciplinary{" "}
            <a href="http://nuvis.northeastern.edu/">
              NUVis Visualization Consortium
            </a>{" "}
            ties these researchers together, and helps foster cross-cutting
            studies. We have a rich tradition of working on meaningful problems
            in collaboration with civic, business, and global partners.
          </p>
          <p>
            Our warm supportive community of researchers eagerly seeks women and
            minority students, postdocs, research scientists, and faculty to
            promote diversity.
          </p>

          <PositionTitle>Faculty</PositionTitle>
          <p>
            Khoury College is searching more broadly for professors, teaching
            professors, and lecturers across all areas. See the{" "}
            <a href="https://www.khoury.northeastern.edu/information-for-overview/prospective-faculty/open-positions/">
              college's open positions page
            </a>{" "}
            for more details.
          </p>

          <PositionTitle>Postdoctoral Researcher</PositionTitle>
          <p>
            We have multiple open roles for postdocs in visualization and visual
            analytics with{" "}
            <a className="at" href="/people/Michael-Correll/">
              Michael Correll
            </a>
            ,{" "}
            <a className="at" href="/people/Clifton-Forlines/">
              Clifton Forlines
            </a>
            , and{" "}
            <a className="at" href="/people/Mahsan-Nourani/">
              Mahsan Nourani
            </a>
            .
          </p>

          <p>
            <a className="at" href="/people/Lace-Padilla/">
              Prof. Lace Padilla
            </a>{" "}
            has an open postdoc position in information visualization and
            uncertainty communication. See the{" "}
            <a href="https://northeastern.wd1.myworkdayjobs.com/en-US/careers/details/Postdoctoral-Research-Associate--Khoury-College-of-Computer-Sciences_R120246">
              job posting
            </a>{" "}
            for more information about the role and application instructions.
          </p>

          <PositionTitle>Student Research</PositionTitle>
          <PositionSubTitle>
            PhD Research
            <strong>
              <br />
            </strong>
          </PositionSubTitle>
          <p>
            At Northeastern, students interested in graduate studies on
            visualization have{" "}
            <strong>several degree programs to choose from</strong>. We offer
            PhD programs in{" "}
            <a href="https://www.khoury.northeastern.edu/program/computer-science-phd/">
              Computer Science
            </a>
            ,{" "}
            <a href="https://phi.khoury.northeastern.edu/">
              Personal Health Informatics
            </a>
            ,{" "}
            <a href="http://www.networkscienceinstitute.org/phd">
              Network Science
            </a>
            , and{" "}
            <a href="https://www.khoury.northeastern.edu/programs/cybersecurity-phd/">
              Cybersecurity
            </a>
            . We will help guide incoming students in applying to the
            appropriate degree program for their interests.
          </p>
          <p>
            (1){" "}
            <a className="at" href="/people/Lace-Padilla/">
              Prof. Lace Padilla
            </a>{" "}
            has <strong>two open and funded PhD positions</strong> for students
            interested in data visualization, human perception, and cognition.
            More information about the roles is available on{" "}
            <a href="https://www.lacepadilla.com/open.html">her website</a>.
          </p>
          <p>
            (2) We have <strong>two open and funded PhD positions</strong>{" "}
            located at the Roux Institute in Portland, Maine, to work with Profs{" "}
            <a className="at" href="/people/Clifton-Forlines/">
              Forlines
            </a>
            ,{" "}
            <a className="at" href="/people/Michael-Correll/">
              Correll
            </a>
            ,{" "}
            <a className="at" href="/people/Mahsan-Nourani/">
              Nourani
            </a>
            , and
            <a className="at" href="/people/Melanie-Tory/">
              Tory
            </a>
            . Topics include but are not limited to: Human-AI interaction and AI
            explainability, uncertainty visualization, AR/VR, physiological
            sensing, and applications in Engineering and Biomedicine.
          </p>
          <p>
            (3){" "}
            <a className="at" href="/people/Cody-Dunne/">
              Prof. Cody Dunne
            </a>{" "}
            currently has <strong>two open and funded PhD positions</strong>,
            possibly focused on layered graph drawing / network visualization,
            reverse engineering software, cybersecurity and defense geospatial
            network visualization, supply chain visualization, SQL query
            visualization, temporal event sequence visualization esp. for
            healthcare, multidimensional data visualization, and visualization
            perception and cognition.
          </p>
          <p>
            PhD students in these programs who are making acceptable progress
            are guaranteed at least five years of full funding.{" "}
            <strong>
              Spring PhD admission is available for exceptional students.
            </strong>
          </p>
          <PositionSubTitle>
            Undergraduate &amp; Master's Research
          </PositionSubTitle>
          <p>
            We are also looking for exceptional undergraduate and master's
            students to work as part of dynamic teams with our graduate students
            and faculty. Research with us can be paid or unpaid for research
            experience. We hire students hourly or for co-ops.
          </p>
          <PositionSubTitle>
            How to Apply
            <strong>
              <br />
            </strong>
          </PositionSubTitle>
          <p>
            We are particularly interested in candidates with strong programming
            proficiency, such as full stack web development experience,
            JavaScript, D3, WebGL, Python, and Java. Experience with user study
            design, perceptual psychology, and vision science is also valuable.
            Candidates should have strong English speaking and writing skills.
          </p>
          <p>
            If you are interested in applying to work with us, please email us
            at{" "}
            <a href="mailto:visualization-apply@ccs.neu.edu">
              visualization-apply@ccs.neu.edu
            </a>{" "}
            including:
          </p>
          <ul>
            <li>
              A statement of your research interests, which of us you want to
              work with, and what work of ours is interesting to you;
            </li>
            <li>Your CV;</li>
            <li>Academic transcripts (can be unofficial);</li>
            <li>
              Test scores for students: GRE for graduate students, SAT for
              undergraduate, and TOEFL/IELTS if foreign; and
            </li>
            <li>
              Any additional materials that would help us evaluate your skills,
              such as your homepage, design portfolio, online demos or videos,
              and GitHub profile.
            </li>
          </ul>
          <p>
            If we think you would be suitable research partners, we will
            schedule an interview with you.
          </p>
          <p>
            The official PhD program application form is available{" "}
            <a href="https://www.khoury.northeastern.edu/apply/phd-apply/">
              here
            </a>
            .
          </p>
          {/* <StyledSubTitle>Career Counselors and Faculty</StyledSubTitle>
          <p>
            Please consider posting{" "}
            <a href="https://vis.khoury.northeastern.edu/files/2020/10/Data_Visualization_at_Northeastern_Khoury.pdf">
              our student recruiting flier
            </a>{" "}
            (from 2020).
          </p>
          <p>
            <a href="https://vis.khoury.northeastern.edu/files/2020/10/Data_Visualization_at_Northeastern_Khoury.pdf">
              <img
                loading="lazy"
                className="alignnone size-medium wp-image-106"
                src="https://vis.khoury.northeastern.edu/files/2020/10/Data_Visualization_at_Northeastern_Khoury-791x1024.png"
                alt="Image of flier about data visualization at Northeastern Khoury"
                width="232"
                height="300"
              />
            </a>
          </p> */}
        </div>
      </RecruitmentContent>
    </RecuitmentContainer>
  );
};

export default Recruitment;
