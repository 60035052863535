import React from "react";
import dataHome from "./data/home.json";
import dataPapers from "./data/papers.json";
import dataPeople from "./data/people.json";
import dataProjects from "./data/projects.json";
import dataBlog from "./data/blog.json";
import dataTeaching from "./data/teaching.json";
import dataFunding from "./data/funding.json";
import dataCulture from "./data/culture.json";
import Pages from "./pages";

function App() {
  // Reverse so last papers added show up first as a proxy for date of publication
  // Relies on sort being stable, which is the case for ES 2019+
  dataPapers.publications = dataPapers.publications.slice().reverse();
  return (
    <Pages
      home={dataHome}
      papers={dataPapers}
      people={dataPeople}
      projects={dataProjects}
      blog={dataBlog}
      teaching={dataTeaching}
      funding={dataFunding}
      culture={dataCulture}
    />
  );
}

export default App;
