import React from "react";
import { PubRow, StyledImage, PubTitle } from "./styles";
import { Col } from "react-bootstrap";
import Materials from "../Materials";
import Authors from "../Authors";

const Citation = ({ pub, people, showDoi = false }) => {
  let paperLoc = "/pubs/" + pub.urlId + "/";

  const doiUrl = pub?.materials?.DOI ?? "";
  const doiPrefix = "https://doi.org/";
  const doiDisplay = doiUrl.startsWith(doiPrefix)
    ? doiUrl.slice(doiPrefix.length)
    : doiUrl;

  return (
    <PubRow key={pub.urlId}>
      <Col xs={6} md={2}>
        <a href={process.env.PUBLIC_URL + paperLoc}>
          {
            pub.teaserImg ? (
              <StyledImage
                src={
                  process.env.PUBLIC_URL +
                  "/Image/paper/teaser/" +
                  pub.teaserImg
                }
                thumbnail
                alt={"Thumbnail image for publication titled: " + pub.title}
              />
            ) : null
            // <StyledImage
            //   src={process.env.PUBLIC_URL + "/Image/default.png"}
            //   thumbnail
            //   alt={"Default blank thumbnail image"}
            // />
          }
        </a>
      </Col>
      <Col xs={12} md={8}>
        <PubTitle href={process.env.PUBLIC_URL + paperLoc}>
          {pub.title}
        </PubTitle>
        <p>
          <Authors names={pub.authors} people={people} />. <i>{pub.venue}.</i>{" "}
          {pub.year}.
          {showDoi && doiUrl && (
            <>
              {" DOI: "}
              <a href={doiUrl}>{doiDisplay}</a>
            </>
          )}
        </p>
        <p>
          <Materials
            bibTex={[pub.bibTex, pub.urlId]}
            materials={pub.materials}
            award={pub.award}
          />
        </p>
      </Col>
    </PubRow>
  );
};

export default Citation;
