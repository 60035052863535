import styled from "styled-components";

export const RecuitmentContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const RecruitmentContent = styled.div`
  max-width: 55em;
`;

export const PositionTitle = styled.h4`
  font-family: "HelveticaNeueW01-67MdCn 692710", "HelveticaNeueW01-45Ligh",
    "Helvetica Neue", HelveticaNeue, Helvetica, sans-serif;
  font-weight: normal;
  line-height: 1.1;
  color: #615445;
  font-size: 24px;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

export const PositionSubTitle = styled.h5`
  font-family: "HelveticaNeueW01-67MdCn 692710", "HelveticaNeueW01-45Ligh",
    "Helvetica Neue", HelveticaNeue, Helvetica, sans-serif;
  font-weight: normal;
  line-height: 1.1;
  color: #615445;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 10px;
`;
