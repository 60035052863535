import React from "react";
import { StyledTitle } from "../../styles";
import {
  FundingContainer,
  FundingContent,
  Funder,
  DateRange,
  Amount,
  Title,
  PIs,
  PILabel,
  FundingAgencyDescription,
} from "./styles";
import { Stack, Row, Col } from "react-bootstrap";
import Authors from "../../components/Authors";

const Grant = ({ grant, people }) => {
  return (
    <div>
      <Row className="align-items-baseline">
        <Col className="mb-3">
          <Funder>{grant.funder}</Funder>
        </Col>
        <Col md="auto" className="mb-3">
          <Amount>
            {grant.amount}
            {grant.amount_we_get && <> ({grant.amount_we_get})</>}
          </Amount>
        </Col>
      </Row>

      <Row className="align-items-baseline">
        <Col className="mb-3">
          <Title>
            {grant.url ? <a href={grant.url}>{grant.title}</a> : grant.title}
          </Title>
        </Col>
        <Col md="auto" className="mb-3">
          <DateRange>
            {grant.start_date} – {grant.end_date}
          </DateRange>
        </Col>
      </Row>

      <Row>
        <Col className="mb-3">
          <PIs>
            <PILabel>{grant.PIs.length > 1 ? "PIs: " : "PI: "}</PILabel>
            <Authors names={grant.PIs} people={people} />
            {grant.CoPIs && grant.CoPIs.length > 0 && (
              <>
                <PILabel>
                  {grant.CoPIs.length > 1 ? " Co-PIs: " : " Co-PI:"}
                </PILabel>
                <Authors names={grant.CoPIs} people={people} />
              </>
            )}
          </PIs>
        </Col>
      </Row>

      {grant.funding_agency_description && (
        <Row>
          <Col className="mb-3">
            <FundingAgencyDescription>
              {grant.funding_agency_description}
            </FundingAgencyDescription>
          </Col>
        </Row>
      )}
    </div>
  );
};

const Funding = ({ funding, people }) => {
  const grants = funding["Grants"]
    .slice()
    .sort((a, b) => Date.parse(b.start_date) - Date.parse(a.start_date));

  return (
    <FundingContainer>
      <FundingContent>
        <StyledTitle className="mb-4">Funding</StyledTitle>

        <p className="mb-4">
          Our group is thankful to receive funding from many sources. Below, we
          list the size of the grants along with our portion of those grants in
          parenthesis.
        </p>

        <Stack direction="vertical" gap={4}>
          {grants.map((grant) => (
            <Grant grant={grant} people={people} key={grant.title} />
          ))}
        </Stack>
      </FundingContent>
    </FundingContainer>
  );
};

export default Funding;
