import React from "react";
import { Figure, Row, Col, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Citation from "../../components/Citation";
import Materials from "../../components/Materials";
import Authors from "../../components/Authors";
import { StyledHeading } from "./styles";
import { StyledTitle } from "../../styles";

const Layout = ({ ele }) => {
  if (ele.p) {
    return (
      <Col xs={18} md={12}>
        <p>{ele.p}</p>
      </Col>
    );
  } else if (ele.link) {
    var link = ele.link;
    return (
      <Col xs={18} md={12}>
        {link.text}
        <Button variant="link" href={link.url}>
          {link.linkText}
        </Button>
      </Col>
    );
  } else if (ele.image) {
    var image = ele.image;
    return (
      <Col xs={12} md={8}>
        <Figure>
          <Figure.Image
            height={image.height ? image.height : "100%"}
            width={image.width ? image.width : "100%"}
            src={process.env.PUBLIC_URL + image.src}
          />
          <Figure.Caption>{image.caption}</Figure.Caption>
        </Figure>
      </Col>
    );
  } else if (ele.video) {
    var video = ele.video;
    return (
      <Col xs={9} md={4}>
        <iframe
          title={ele.title}
          width={video.width ? video.width : "100%"}
          height={video.height ? video.height : "100%"}
          src={process.env.PUBLIC_URL + video.src}
          frameBorder="0"
          allow={video.allow}
          allowFullScreen
        ></iframe>
      </Col>
    );
  } else if (ele.html) {
    return <div dangerouslySetInnerHTML={{ __html: ele.html }}></div>;
  } else if (ele && ele.length > 1) {
    return (
      <>
        {ele.map((entry, index) => (
          <Layout key={index} ele={entry} />
        ))}
      </>
    );
  } else {
    console.log("This type is not implemented:", ele);
    return <></>;
  }
};

const Project = ({ projects, people, papers }) => {
  let { projectId } = useParams();
  let project = projects.find((p) => p.urlId === projectId);
  let pubs = papers.filter((paper) => project.citations.includes(paper.urlId));
  return (
    <>
      <StyledTitle>{project.title}</StyledTitle>
      <h5>
        <Authors names={project.authors} people={people} />
      </h5>
      <a href="#Materials">Materials</a>
      {" | "}
      <a href="#Citations">Citations</a>
      {project.layout &&
        project.layout.map((option, index) => (
          <Row key={index} className="justify-content-md-center">
            <Layout ele={option} />
          </Row>
        ))}
      <StyledHeading id="Materials">Materials</StyledHeading>
      <Materials materials={project.materials} />
      {pubs && <StyledHeading id="Citations">Citations</StyledHeading>}
      {pubs.map((pub, index) => (
        <Citation key={index} pub={pub} people={people} />
      ))}
    </>
  );
};

export default Project;
