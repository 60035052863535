import React from "react";

import { Link } from "react-router-dom";
import { Nav, Navbar } from "react-bootstrap";
import { StyledNavSub, HeaderSpan, StyledLink } from "./styles";

const items = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "People",
    path: "/people/",
  },
  {
    name: "Publications",
    path: "/pubs/",
  },
  // { // Temporarily hidden
  //   name: "Projects",
  //   path: "/projects",
  // },
  {
    name: "Open Positions",
    path: "/open-positions/",
  },
  {
    name: "Teaching",
    path: "/teaching/",
    // subpath: [
    //   {
    //     name: "Awards",
    //     path: "/publicity#awards",
    //   },
    //   {
    //     name: "Press",
    //     path: "/publicity#press",
    //   },
    // ],
  },
  {
    name: "Funding",
    path: "/funding/",
  },
  {
    name: "Culture",
    path: "/culture/",
  },
  {
    name: "Blog",
    path: "/blog/",
  },
];

const Header = () => {
  return (
    // <Navbar expand = "lg" bg="light" variant="light" fixed= "top">

    <Navbar fluid="true" expand="xxl">
      <Navbar.Brand
        style={{ fontSize: "2rem", color: "#e11a2c" }}
        href={process.env.PUBLIC_URL + "/"}
      >
        <img
          src={process.env.PUBLIC_URL + "/Lablogo_black.svg"}
          width="80"
          height="80"
          className="d-inline-block align-top"
          alt="Data Vis logo"
        />
        <HeaderSpan>Khoury Vis Lab</HeaderSpan>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ml-auto">
          {items.map((i, idx) => (
            <Nav.Item key={idx} className="flex-column">
              {/* <StyledNavMain style={{ color: "#000000" }} key={i.name}> */}
              <StyledLink to={i.path} exact={i.path === "/"}>
                {" "}
                {i.name}
              </StyledLink>
              {/* </StyledNavMain> */}
              {i.subpath &&
                i.subpath.map((j) => (
                  <StyledNavSub element={Link} href={"#" + j.path} key={j.name}>
                    {j.name}
                  </StyledNavSub>
                ))}
            </Nav.Item>
          ))}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
