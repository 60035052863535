import styled from "styled-components";

export const AwardStyle = styled.span`
  color: #e41a1c;
  font-weight: 600;
`;

export const PubMaterial = styled.a`
  color: #000;
  text-decoration: none;
`;
