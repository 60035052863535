import styled from "styled-components";

export const FundingContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const FundingContent = styled.div`
  max-width: 55rem;
  line-height: 1.1;
`;

export const Funder = styled.div`
  font-size: 1.25rem;
  font-weight: 700;
`;

export const Amount = styled.div`
  font-size: 1.125rem;
`;

export const DateRange = styled.div`
  font-size: 1.125rem;
`;

export const Title = styled.div`
  font-size: 1.125rem;
`;

export const PIs = styled.div``;

export const PILabel = styled.span`
  font-weight: 700;
`;

export const CoPIs = styled.div``;

export const FundingAgencyDescription = styled.div``;
