import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import { StyledTitle } from "../../styles";
import { Col } from "react-bootstrap";
import { PostRow, PostTeaserTitle } from "./styles";
import Authors from "../../components/Authors";
import BlogPost from "./BlogPost";

const BlogPostTeaser = ({ post, people }) => {
  let postLoc = "/blog/" + post.slug + "/";
  return (
    <PostRow key={post.slug}>
      <Col>
        <PostTeaserTitle to={postLoc}>{post.title}</PostTeaserTitle>
        <div>
          <Authors names={post.authors} people={people} />
        </div>
        <div>{post.date}</div>
      </Col>
    </PostRow>
  );
};

const Blog = ({ posts, people }) => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <StyledTitle id="blog-posts">Blog</StyledTitle>
        {posts.map((post) => (
          <BlogPostTeaser key={post.slug} post={post} people={people} />
        ))}
      </Route>
      <Route path={`${path}/:postId`}>
        <BlogPost posts={posts} people={people} />
      </Route>
    </Switch>
  );
};

export default Blog;
