import styled from "styled-components";
import { Image, Row } from "react-bootstrap";

export const StyledImage = styled(Image)`
  transition: transform 0.2s;
  width: 100%;
  object-fit: cover;
  &:hover {
    cursor: pointer;
    -moz-box-shadow: 0 0 10px #377eb8;
    -webkit-box-shadow: 0 0 10px #377eb8;
    box-shadow: 0 0 10px #377eb8;
  }
`;

export const PubTitle = styled.a`
  font-weight: 600;
  color: #000;
  text-decoration: none;
  &:hover {
    color: #377eb8;
    text-decoration: none;
  }
`;
export const PubRow = styled(Row)`
  padding: 10px 0px;
`;
